@import "./vars.scss";

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--text);
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--background);
}

.bm-cross-button > span {
  top: 4px !important;
}

/* This targets the actual elements that make up the cross */
.bm-cross {
  width: 5px !important;
  height: 25px !important;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--text);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: var(--text);
}

/* Wrapper for item list */
.bm-item-list {
  color: var(--background);
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  font-size: 16px;
  padding: 12px;
  text-decoration: none;
}

/* Custom styles */

.hamburger--fixed-header {
  display: flex;
  height: 100px;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
.hamburger--white {
  background-color: var(--background);
  transition: all .2s ease-in-out;
  .hamburger--custom-icon {
    width: 35px;
    height: 5px;
    background-color: var(--text);
    margin: 8px 0;
  }
}
.hamburger--black {
  background-color: var(--text);
  transition: all .2s ease-in-out;
  .hamburger--custom-icon {
    width: 35px;
    height: 5px;
    background-color: var(--background);
    margin: 8px 0;
  }
}
.hamburger--custom-button {
  background: none;
  border: none;
  outline: none;
}

.hamburger--contact {
  border: 2px solid var(--background);
  border-radius: var(--border-radius--sm);
  color: var(--background);
  margin-top: 40px;
  text-align: center;
}
.hamburger--toggle {
  margin-top: 40px;
}

// hide hamburger menu for desktop
@media screen and (min-width: 1024px) {
  .hamburger--wrapper {
    display: none;
  }
}
