@import "./vars.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap");

// General

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito Sans", serif
}

a {
  color: var(--link);
  font-weight: 700;
  text-decoration: none;
}

.skip-link {
  background: var(--text);
  color: var(--background);
  display: block;
  font-family: "Roboto", sans-serif;
  left: -999px;
  padding: 16px;
  position: absolute;
  top: -999px;
}

.skip-link:focus {
  left: 0;
  top: 0;
}

.heading {
  font-size: var(--text-lg);
}
.fade-in-section {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.section-header {
  text-align: center;
}

.main--content {
  background: var(--background);
  color: var(--text);
}

//Header

.header {
  display: none;
}

.dark-mode {
  filter: invert(1);
  img, video, .header, .header--black, .header--white, .about {
    filter: invert(1);
  }
  .about--textbox {
    background: rgba(0,0, 0, 0.6) !important;
    color: var(--white)

  }
}

// About

.about {
  align-items: center;
  background: url(assets/website-cover.png);
  background-size: cover;
  display: flex;
  font-size: var(--text-md);
  height: 100vh;

  .about--textbox {
    background: rgba(210, 211, 220, 0.5);
    border-radius: var(--border-radius--sm);
    max-width: 570px;
    padding: 40px;
  }
  .about--pronouns {
    font-style: italic;
  }
  .about--university:hover {
    background: linear-gradient(to right, #107dee ,30%, #58eba0) left bottom no-repeat;
    background-size: 100% 3px;
  }
}

// Experience

.experience {
  margin-top: var(--spacer);
}

// Projects and Blogs

.skills-section {
  .skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .skill {
    border-radius: var(--border-radius--lg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.20);
    max-width: 452px;
    margin: 30px auto;
    padding: 20px;
    transition: box-shadow .2s ease-in-out;
  }
  .skill:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.20);
  }
  .skill--short {
    max-height: 440px;
    max-width: 352px;
  }
  .skill--image, .skill--image--small {
    border-radius: var(--border-radius--sm);
    margin: auto;
    width: 100%;
  }
  .skill--image {
    max-width: 412px;
  }
  .skill--image--small {
    margin: auto;
    max-width: 232px;
  }
  .skill--title {
    color: var(--text-light);
    font-size: var(--text-sm);
    letter-spacing: 2.1px;
    text-transform: uppercase;
  }
  .embedded-photo--container {
    text-align: center;
    .embedded-photo {
      width: 100%;
      max-width: 452px;
    }
  }
}
// Footer

.footer {
  margin-top: var(--spacer);
  background: var(--text);
  color: var(--background);
  padding: 40px;
  text-align: center;
  .socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
    max-width: 1062px;
    padding-bottom: 10px;
  }
  .social {
    margin: 4px;
    padding: 10px;
  }
}

//Desktop

@media only screen and (min-width: 1024px) {

  // Header

  .header {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
  .header--white {
    background: var(--background);
    color: var(--text);
    transition: all .2s ease-in-out;

    .header--contact {
      background: var(--text);
      color: var(--background);
      padding: 22px 42px;
    }
  }
  .header--black {
    background: var(--text);
    color: var(--background);
    transition: all .2s ease-in-out;

    .header--contact {
      border: 2px solid var(--background);
      color: var(--background);
      padding: 20px 40px;
    }
  }
  .header--navigation {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: space-around;
    margin: auto;
    max-width: 1500px;
  }
  .header--link {
    cursor: pointer;
    padding: 8px 0;
    margin: 40px;
  }
  .header--link:hover {
    background: linear-gradient(to right, #107dee ,30%, #58eba0) left bottom no-repeat;
    background-size: 100% 3px;
  }
  .header--contact {
    border-radius: var(--border-radius--sm);
    cursor: pointer;
  }

  // About
  .about--title {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  // Projects and Blogs

  .skills-section {
    .skills {
      flex-direction: row;
    }
    .skill {
      margin: 30px;
    }
  }

  // Footer
  .footer {
    .social {
      margin: 44px 4px;
    }
    .social:hover {
      background: linear-gradient(to right, #107dee ,30%, #58eba0) left bottom no-repeat;
      background-size: 100% 3px;
    }
  }
}

// Mobile thumbnail
@media only screen and (max-width: 640px) {
  // About
  .about {
    height: auto;
    .about--textbox {
      padding-top: 200px;
    }
  }
}
