@import "./vars.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap");

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  li {
    padding: 5px 0;
  }
  li::before {
    display: inline-block;
    content: "-";
    width: 1em;
    margin-left: -1em;
  }
}
.active-arrow {
  border: solid var(--text);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right-arrow {
  height: 22px;
  width: 40px;
}
.dark-mode-arrow {
  filter: unset !important;
}
.experience--logo {
  height: 25px;
}
.nuffsaid--logo, .sibros--logo, .pencil--logo {
  border-radius: var(--border-radius--xs);
  height: 30px;
}
.nuffsaid--logo, .sibros--logo {
  background: var(--white);
}
.pencil--logo {
  background: var(--pencil);
}
.pencil--logo, .nuffsaid--logo {
  padding: 6px;
}
.sibros--logo {
  padding: 3px;
}

/*custom styles*/

.carousel--container {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 1500px;
}
.carousel {
  overflow: auto;
  white-space: nowrap;
  width: 100%;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.carousel::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE and Edge */
.carousel {
  -ms-overflow-style: none;
}
.carousel-item {
  display: inline-block;
  margin: 15px;
  padding: 25px 0;
  height: 500px;
}

.carousel-item--template {
  background: var(--text);
  color: var(--white);
}

.carousel-item--template,
.carousel-item--experience {
  border-radius: var(--border-radius--lg);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 25px 25px 0 25px;
  text-decoration: none;
  width: 450px;
}
.carousel-item--experience {
  background: var(--background);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.20);
  cursor: pointer;
  transition: box-shadow .2s ease-in-out;
}
.carousel-item--experience:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.20);
}
.experience--header,
.experience-template--header {
  display: inline-block;
  white-space: pre-wrap;
}
.experience--header {
  font-weight: bold;
}
.experience-template--header {
  max-width: 266px;
}
.experience--copy {
  max-width: 370px;
  white-space: pre-line;
}
.carousel-item:first-of-type {
  position: relative;
  top: 18px;
}
.experience--footer,
.experience-template--footer {
  margin-bottom: 24px;
}
.experience-template--footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.navigation--button {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
