:root {
  --background: var(--white);
  --border: #e1e1e6;
  --text: var(--black);
  --pencil: #043160;
  // tones
  --text--light: #3b4651;
  // colors
  --white: #ffffff;
  --black: #000000;
  // flair
  --border-radius--xs: 5px;
  --border-radius--sm: 10px;
  --border-radius--lg: 15px;
  --spacer: 100px;
  // sizing
  --text-sm: 16px;
  --text-md: 24px;
  --text-lg: 36px;
}
